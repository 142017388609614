



<template>

    <Section :options="computedOptions.sectionOptions">
        <div :class="dividerCssClass">
            <img v-if="computedOptions.picture" :src="computedOptions.picture" :alt="$cms.utils.getComputedLabel(labels.pictureAlt)">
            <i v-else :class="'icon-'+computedOptions.icon"></i>
        </div>
    </Section>
    
</template>

<style>

.divider > img {
    max-height:100px;
    max-width:1024px;
}

</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore, useCmsModule } from '@fwk-client/utils/vue-3-migration';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import { CmsPicture, CmsEnum, CmsLabel, CmsContent, CmsBoolean, CmsText } from '@fwk-client/modules/cms/types';
import { SocialMedias } from '../social/types';
import SocialLinks from '../social/SocialLinks.vue';

/** @cmsOptions */
export interface DividerOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions,
    /** @cmsType CmsPicture */
    picture?:CmsPicture;
    /** @cmsType CmsEnum */
    position?:CmsEnum<"left"|"right"|"center">;
    /** @cmsType CmsBoolean */
    rounded?:CmsBoolean;
    /** @cmsType CmsBoolean */
    shortLength?:CmsBoolean;
    /** @cmsType CmsText */
    icon?:CmsText;
}

/** @cmsLabels */
export interface DividerLabels {
    /** @cmsType CmsLabel */
    pictureAlt?:CmsLabel;
}

/** @cmsSlots */
export interface DividerSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<DividerOptions>,
        labels: {
          type: Object as PropType<DividerLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<DividerSlots>
    },
    components: {
        Section,
        LocalizedContent,
        SocialLinks
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        
        const computedOptions:DividerOptions = {
            sectionOptions: {
                section:true, 
                container:false
            },
            position:"center",
            icon:"circle",
            ...props.options,
            picture : (props.options && props.options.picture) ? cms.utils.getComputedPictureURL(props.options.picture) : undefined
        };

        const dividerCssClass = computed(() => {
            let cssClass = "divider";
            if(computedOptions.shortLength == true) {
                cssClass += " divider-sm";
            }
            if(computedOptions.rounded == true) {
                cssClass += " divider-rounded";
            }
            if(computedOptions.position == "right") {
                cssClass += " divider-right";
            } else if(computedOptions.position == "center") {
                cssClass += " divider-center";
            }
            return cssClass;
        });

        return {
            dividerCssClass,
            computedOptions,
            labels:props.labels
        }

    }
})
</script>